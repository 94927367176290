import { parameterize } from "@lib/utils"

const transformed = (input) => {
  return {
    ...input,
    uniqueId: parameterize(input.uniqueId || input.title),
    image: {
      src: input.image?.src || input.image?.url,
      alt: input.image?.alt || input.image?.description,
      width: input.image?.width,
      height: input.image?.height
    },
    backgroundColor: parameterize(input.backgroundColor)
  }
}

export default transformed
