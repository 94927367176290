// ---------------------------------------------------------

const wyndhamLogo = {
  src: "/storybook/images/logo-train/wyndham-new.svg",
  alt: "Wyndham"
}

const tapestryLogo = {
  src: "/storybook/images/logo-train/tapestry.svg",
  alt: "Tapestry"
}

const alaskaLogo = {
  src: "/storybook/images/logo-train/alaska.svg",
  alt: "Alaska Airlines"
}

const brooksBrothersLogo = {
  src: "/storybook/images/logo-train/brooks_brothers.svg",
  alt: "Brooks Brothers"
}

const dsgLogo = {
  src: "/storybook/images/logo-train/dsg.svg",
  alt: "Dick's Sporting Goods"
}

const paramountLogo = {
  src: "/storybook/images/logo-train/paramount.svg",
  alt: "Paramount"
}

const servcoLogo = {
  src: "/storybook/images/logo-train/servco.svg",
  alt: "Servco"
}

const soundersLogo = {
  src: "/storybook/images/logo-train/sounders.svg",
  alt: "Sounders"
}

const tumiLogo = {
  src: "/storybook/images/logo-train/tumi.svg",
  alt: "TUMI"
}

const forever21Logo = {
  src: "/storybook/images/logo-train/forever_21.svg",
  alt: "Forever 21"
}

const kendraScottLogo = {
  src: "/storybook/images/logo-train/kendra_scott.svg",
  alt: "Kendra Scott"
}

// ---------------------------------------------------------

export const logos = [
  {
    ...soundersLogo
  },
  {
    ...servcoLogo
  },
  {
    ...paramountLogo
  },
  {
    ...alaskaLogo
  },
  {
    ...forever21Logo
  },
  {
    ...tumiLogo
  },
  {
    ...tapestryLogo
  },
  {
    ...wyndhamLogo
  }
]

// ---------------------------------------------------------

export default {
  props: {
    title: "Trusted by the world's most beloved brands",
    button: {
      labelAttr: "View all customers",
      url: "https://example.com"
    },
    logos: logos
  },
  withLabel: {
    logos: logos,
    label: "Trusted by the world's most beloved brands"
  },
  static: {
    logos: logos
  },
  animated: {
    animated: true,
    logos: [...logos, brooksBrothersLogo, kendraScottLogo, dsgLogo, tapestryLogo]
  },
  animatedLight: {
    animated: true,
    backgroundColor: "white",
    logos: [
      alaskaLogo,
      brooksBrothersLogo,
      dsgLogo,
      paramountLogo,
      servcoLogo,
      soundersLogo,
      tumiLogo
    ]
  },
  withLinks: {
    logos: [
      {
        ...soundersLogo,
        link: "https://www.example.com/"
      },
      {
        ...dsgLogo,
        link: "https://www.example.com/"
      },
      {
        ...alaskaLogo,
        link: "https://www.example.com/"
      },
      {
        ...servcoLogo,
        link: "https://www.example.com/"
      },
      {
        ...brooksBrothersLogo,
        link: "https://www.example.com/"
      },
      {
        ...tumiLogo,
        link: "https://www.example.com/"
      },
      // {
      //   ...wyndhamLogo,
      //   link: "https://www.example.com/"
      // },
      {
        ...paramountLogo,
        link: "https://www.example.com/"
      }
    ]
  }
}
