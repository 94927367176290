import React from "react"
import Component from "./component"
import fixtures from "./fixtures"
import transform from "./transformer"

const Grid = (props) => <Component {...transform(props)} />

export default Grid

export { Component as component, transform as transformer, fixtures }
