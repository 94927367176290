import { parameterize } from "@lib/utils"

const transformed = (input) => {
  let logos = input.logos?.items || input.logos

  return {
    ...input,
    logos: logos.map((logo) => {
      return {
        alt: logo.image?.alt || logo.alt || logo.description,
        link: logo.link,
        src: logo.image?.src || logo.src || logo.url
      }
    }),
    backgroundColor: parameterize(input.backgroundColor)
  }
}

export default transformed
