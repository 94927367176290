import { parameterize } from "@lib/utils"

export const transformButton = (input) => {
  return {
    ...input,
    theme: parameterize(input?.theme)
  }
}

const transformed = (input) => {
  return {
    ...input,
    theme: parameterize(input?.theme),
    backgroundColor: parameterize(input.backgroundColor),
    labelColor: parameterize(input.labelColor),
    title: parameterize(input.title)
  }
}

export default transformed
