import { parameterize } from "@lib/utils"

const convertSize = (size) => {
  const parsedSize = parameterize(size) || "medium"
  switch (parsedSize) {
    case "none":
      return "0"
    case "small":
      return "40"
    case "medium":
      return "80"
    case "large":
      return "120"
    default:
      return "80"
  }
}

const transformed = (input) => {
  return {
    ...input,
    column: input.column,
    verticalAlignment: parameterize(input.verticalAlignment),
    backgroundAngle: parameterize(input.backgroundAngle),
    backgroundColor: parameterize(input.backgroundColor),
    paddingTop: convertSize(input.paddingTop),
    paddingBottom: convertSize(input.paddingBottom),
    width: parameterize(input.width)
  }
}

export default transformed
