import React from "react"

import { error_container } from "./styles.module.scss"
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    // Define a state variable to track whether is an error or not
    // Definte a state variable to track error details for sentry report
    this.state = { hasError: false, error: "", eventId: "" }
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI or it gets stuck as false
    console.error(error)
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // component caught error
    // report the error to sentry here
    console.log({ error, errorInfo })
  }
  render() {
    // Renders a custom fallback UI on error
    if (this.state.hasError) {
      return (
        <div data-cy="error-boundary" className={error_container}>
          <p>
            Oops, something went wrong.
            <br /> Please reload the page.
          </p>
        </div>
      )
    }

    // Return children components in case of no error
    return this.props.children
  }
}

export default ErrorBoundary
