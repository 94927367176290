import React, { Fragment } from "react"
import Card from "../card"

// ---------------------------------------------------------

const GridItem = (
  <Card
    title="Card title"
    description="Consectetur adipiscing elit. Maecenas sagittis mi vel justo blandit ultricies. Praesent sit
  amet lacinia nunc. Nulla iaculis erat quis sagittis condimentum. Nulla iaculis erat quis
  sagittis condimentum."
    buttonLabel="Learn more"
    buttonUrl="#"
  />
)

const GridItemV2 = (
  <Card
    title="A different card title"
    description="Consectetur adipiscing elit. Maecenas sagittis mi vel justo blandit ultricies. Praesent sit
  amet lacinia nunc."
    buttonLabel="Learn more"
    buttonUrl="#"
  />
)

const TextBlock = (
  <div>
    <h2>Morpheus' Monologue</h2>
    <p>
      I imagine that right now, you’re feeling a bit like Alice. Hmm? Tumbling down the rabbit hole?
      I see it in your eyes. You have the look of a man who accepts what he sees because he is
      expecting to wake up. Ironically, that’s not far from the truth.
    </p>
    <p>
      Do you believe in fate, Neo? I know exactly what you mean. Let me tell you why you’re here.
      You’re here because you know something. What you know you can’t explain, but you feel it.
      You’ve felt it your entire life, that there’s something wrong with the world. You don’t know
      what it is, but it’s there, like a splinter in your mind, driving you mad. It is this feeling
      that has brought you to me. Do you know what I’m talking about? Do you want to know what it
      is?
    </p>
    <p>
      The Matrix is everywhere. It is all around us. Even now, in this very room. You can see it
      when you look out your window or when you turn on your television. You can feel it when you go
      to work… when you go to church… when you pay your taxes. It is the world that has been pulled
      over your eyes to blind you from the truth. … That you are a slave, Neo. Like everyone else
      you were born into bondage. Into a prison that you cannot taste or see or touch. A prison for
      your mind. Unfortunately, no one can be told what the Matrix is. You have to see it for
      yourself. This is your last chance. After this there is no turning back. You take the blue
      pill, the story ends, you wake up in your bed and believe whatever you want to believe. You
      take the red pill, you stay in Wonderland and I show you how deep the rabbit hole goes.
      Remember, all I’m offering is the truth, nothing more. … Follow me.
    </p>
  </div>
)

const props = {
  column: 1,
  width: "default",
  children: <Fragment>{TextBlock}</Fragment>
}

const half = {
  children: (
    <Fragment>
      {GridItem}
      {GridItemV2}
    </Fragment>
  ),
  column: 2,
  width: "default"
}

const multipleSections = {
  column: 2,
  children: (
    <Fragment>
      {GridItem} {GridItemV2} {GridItemV2} {GridItemV2}
    </Fragment>
  )
}
// ---------------------------------------------------------

export default {
  BackgroundAngle: {
    children: (
      <>
        {GridItemV2}
        {GridItemV2}
        {GridItemV2}
      </>
    ),
    column: 3,
    width: "medium",
    backgroundAngle: "small",
    backgroundColor: "cement"
  },
  BackgroundColor: {
    ...props,
    backgroundColor: "lagoon"
  },
  CenterText: {
    ...half,
    centerText: true
  },
  ColumnOne: props,
  ColumnTwo: {
    ...half
  },
  ColumnThree: {
    children: (
      <>
        {GridItem}
        {GridItem}
        {GridItem}
      </>
    ),
    column: 3,
    width: "default"
  },
  ColumnFour: {
    children: (
      <>
        {GridItem}
        {GridItem}
        {GridItem}
        {GridItem}
      </>
    ),
    column: 4,
    width: "default"
  },
  IsRounded: {
    ...props,
    isRounded: true
  },
  IsUneven: {
    ...half,
    isUneven: true
  },
  IsUnevenAndReversedOnMobile: {
    ...half,
    isUneven: true,
    reversedOnMobile: true
  },
  Padding: {
    ...props,
    width: "narrow",
    paddingTop: "120",
    paddingBottom: "80"
  },
  VerticalAlignmentBottom: {
    ...half,
    width: "narrow",
    verticalAlignment: "bottom"
  },
  VerticalAlignmentCenter: {
    ...half,
    width: "narrow",
    verticalAlignment: "center"
  },
  VerticalAlignmentTop: {
    ...half,
    width: "narrow",
    verticalAlignment: "top"
  },
  WidthDefault: {
    ...props,
    width: "default"
  },
  WidthMedium: {
    ...props,
    width: "medium"
  },
  WidthNarrow: {
    ...props,
    width: "narrow"
  },
  MultipleSections: {
    ...multipleSections
  },
  WithLabel: {
    ...half,
    header: "This is the header",
    label: "This is a label"
  }
}
